<template>
    <TPAutocomplete
        :value="value"
        :getter="usersGetter"
        :items="users"
        :defaults="defaults"
        label="Member"
        placeholder="Start typing to Search"
        prepend-icon="person"
        item-value="name"
        :display-fields="['name', 'email']"
        :rules="[validations.required()]"
        :menu-props="userAutocompleteMenuProps"
        @click="userAutocompleteMenuProps.value = true"
        @loaded="userAutocompleteMenuProps.value = true"
        @blur="handleBlurAutocompleteMenu"
        @input="handleInput"
    >
    </TPAutocomplete>
</template>

<script>
import { mapGetters } from "vuex";
import { SEARCH_USER } from "@/store/users.module";
import validations from "@/views/partials/form/Validations.vue";
import TPAutocomplete from "@/views/partials/form/Autocomplete.vue";

export default {
    components: {
        TPAutocomplete
    },
    props: {
        value: Number,
        showPanel: {
            type: Boolean,
            default: true
        },
        defaults:Object
    },
    data() {
        return {
            validations: validations,
            usersGetter: SEARCH_USER,
            userAutocompleteMenuProps: {
                value: false
            }
        };
    },
    computed: {
        ...mapGetters(["users"])
    },
    methods: {
        handleInput(value) {
            this.$emit("input", value);
            this.handleBlurAutocompleteMenu();
        },
        handleBlurAutocompleteMenu() {
            setTimeout(() => {
                this.userAutocompleteMenuProps.value = false;
            }, 100);
        },
        handleProcessing() {
            this.$emit("processing");
        },
        handleProcessed() {
            this.$emit("processed");
        }
    }
};
</script>
