<template>
  <div>
    <v-card :disabled="isProcessing" :loading="isProcessing">
      <template v-slot:progress>
        <v-progress-linear
          absolute
          color="success"
          height="4"
          indeterminate
        ></v-progress-linear>
      </template>
      <v-tabs
        v-model="tab"
        class="deep-gl"
        left
        dark
        icons-and-text
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#tab-1">
          <v-toolbar-title> {{name}}/Team Members  </v-toolbar-title>
        </v-tab>
        <v-tab href="#tab-2">
          <v-toolbar-title> Access & Visibility  </v-toolbar-title>
        </v-tab>
        <v-spacer></v-spacer>

        <div class="buttons-tab"></div>
      </v-tabs>

            <v-form
                @submit.prevent
                ref="requestData"
                v-model="validRequestData"
              >
      <v-tabs-items v-model="tab">
           <template v-slot:progress>
              <v-progress-linear
                absolute
                color="success"
                height="4"
                indeterminate
              ></v-progress-linear>
            </template>
        <v-tab-item value="tab-1">
          <v-card flat>
            <v-card-text>

                <v-row>
                  <v-col cols="12" sm="12">
                    <v-text-field v-model="requestData.name" @change="setChange" label="Name*" required  :rules="[validations.required()]"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                    <v-col lg="12" cols="12">
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th v-for="(header,i) in usersTableHeaders" :key="i" class="text-left">
                                            {{header}}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(user,i) in requestData.users"
                                        :key="i"
                                    >
                                            <td width="50%" class="vertical-middle"><strong>{{user.name}}</strong><br><span>{{user.email}}</span></td>

                                            <td width="30%">
                                                <v-checkbox color="primary" @change="onChange" v-model="user.is_manager"></v-checkbox>
                                            </td>
                                            <td width="20%">
                                                <v-btn class="mt-4" text color="primary" v-on:click="requestData.users.splice(i,1)"><v-icon>mdi-delete</v-icon></v-btn>
                                            </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <AddMember @member="addMember"></AddMember>
              <v-btn color="warning" v-on:click="back(true)">Back</v-btn>
              <v-btn color="success" v-if="hasPermissions(type === 'Create'?'teams-create':'teams-edit')" v-on:click="store()">{{type}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-2">
          <v-card flat>
            <v-card-text>
                <v-row>
                  <v-col lg="12" cols="12">
                            <v-simple-table>
                                <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th v-for="(header,i) in tableHeaders" :key="i" class="text-left">
                                            {{header}}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(permission,i) in permissions"
                                        :key="i"
                                    >
                                            <td width="10%" class="vertical-middle">
                                              {{ i.charAt(0).toUpperCase() + i.slice(1) }}
                                              <template v-if="['live-list', 'price-lists'].includes(i)">
                                              <br /><div class="text-warning">({{ $t('TEAMS.NEED_OFFERS_PERRMISSION_WARNING') }})</div>
                                              </template>
                                            </td>

                                            <td width="15%">
                                                 <v-checkbox color="primary" v-model="access[i]" @change="setAccess($event,i)"></v-checkbox>
                                            </td>
                                            <td v-for="item in permission.filter(x => x.type === 0)" :key="item.id" width="15%">
                                                 <v-checkbox color="primary" v-model="requestData.permissions[i+item.id]" :value="item.id" :disabled="!access[i]" :key="inputChange" @change="onChange"></v-checkbox>
                                            </td>
                                            <td width="40%">
                                                 <v-select
                                                    :items="permission.filter(x => x.type === 1)"
                                                    item-text="text"
                                                    item-value="id"
                                                    v-model="requestData.permissions[i+'View']"
                                                    :disabled="!access[i]"
                                                    @change="onChange"
                                                    :key="inputChange"
                                                ></v-select>
                                            </td>
                                    </tr>
                                </tbody>
                                </template>
                            </v-simple-table>
                  </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="warning" v-on:click="back(true)">Back</v-btn>
              <v-btn color="success" v-on:click="store()">{{type}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
       </v-form>
    </v-card>
     <TPConfirm
            v-model="showConfirmSet"
            content="All current changes will be lost."
            @confirm="back"
        ></TPConfirm>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import validations from "@/views/partials/form/Validations.vue";
import { GET_TEAM_PERMISSIONS_REQUEST,GET_TEAM_REQUEST,CREATE_TEAM_REQUEST,UPDATE_TEAM_REQUEST } from "@/store/teams.module";
import { SHOW_SNACK } from "@/store/snack.module";
import AddMember from "./partials/AddMember.vue";
import TPConfirm from "@/views/partials/dialogs/Confirm.vue";

export default {
  name: "StoreTeam",
  components: {
      AddMember,
      TPConfirm
  },
  data() {
    return {
      name: "Create Team",
      validations: validations,
      validRequestData: false,
      tab: "tab-1",
      tableHeaders: ['Entity Type','Access','Create','Edit','Delete','Member of this team can see'],
      usersTableHeaders: ['Name','Team Manager',''],
      isProcessing: false,
      requestData: {"permissions":{},"users":[]},
      permissions: [],
      showNewTeam: false,
      showNewRole: false,
      changePassword: false,
      newTeamButtonShow:true,
      type:"Create",
      inputChange:0,
      hasChanges:false,
      showConfirmSet:false,
      item:{},
      access: {}
    };
  },
    computed: {
        ...mapGetters(["roles"]),
    },
  mounted() {
     this.selectTeam();
  },
  methods: {

    store() {
      this.$refs.requestData.validate();

      if (!this.validRequestData) {
        return;
      }

      let url = CREATE_TEAM_REQUEST;
      let params = this.requestData;
      if(this.$route.params.id){
         url = UPDATE_TEAM_REQUEST;
         params = {id:this.$route.params.id,params:this.requestData};
      }

      this.isProcessing = true;
      this.$store.dispatch(url,params).then(() => {
          this.$store.dispatch(SHOW_SNACK, {
            message: "Team stored successfuly.",
          });
          this.reloadCurrentUser();
           this.$router.push({
                name: "teams",
            });
        }).catch((error) => {

        })
        .finally(() => {
          this.isProcessing = false;
          this.$refs.requestData.resetValidation();
        });
    },
    showTeam() {
      this.requestData.teams.push({
          "id":null,
          "is_manager":0
      });
      this.showTeamButton()
    },
    showTeamButton(){
        this.newTeamButtonShow = this.requestData.teams.length < this.teams.length
    },
    setAccess(event,type){
         this.hasChanges = true;
        if(event){
            this.requestData.permissions[type+'View'] =  this.permissions[type].find(x => x.name === type+"-view-all").id
            this.access[type] = true;
        }else{
            Object.keys(this.requestData.permissions).forEach(x => {
                return x && x.indexOf(type) !== -1?delete this.requestData.permissions[x]:null
            });
            this.access[type] = false;
        }
         this.inputChange++;
    },
     setChange(){
        this.hasChanges = true;
    },
    onChange(event){
        this.hasChanges = true;
    },
    back(check) {
      if(check && this.hasChanges){
          this.showConfirmSet = true;
      }else{
          this.hasChanges = false;
          this.$router.push({ name: "teams" });
      }
    },
    setInitialRequestData() {
      this.requestData = {
        "permissions":{},
        "users":[]
      };
    },
    addMember(event){
        if(this.requestData.users.indexOf(event) === -1){
            this.requestData.users.push(event);
        }
        this.setChange()
    },
    selectTeam(){
        if(this.$route.params.id){
             this.name = "Update Team";
             this.type = "Update";
             this.isProcessing = true;
             this.$store.dispatch(GET_TEAM_REQUEST,this.$route.params.id).then((response) => {
                this.requestData = response;
                this.access = this.requestData.groups;
                 this.getPermissions();
            }).catch((error) => {
                  this.handleException(error)
            }).finally(() => {
                this.isProcessing = false;
            });
        }else{
            this.getPermissions()
        }
    },
    getPermissions(){
         this.$store.dispatch(GET_TEAM_PERMISSIONS_REQUEST).then(response => {
                this.permissions = response.data;
         })
    }
  },
};
</script>
<style lang="sass" scoped>
.privileges
    margin-top: 50px
    .v-input--checkbox
        margin-top: 10px
        ::v-deep label
            margin-bottom: 0px!important
    h1
        font-size: 16px
        font-weight: bold
    .prev-info
        margin-left: 32px
</style>