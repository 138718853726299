import { render, staticRenderFns } from "./StoreTeam.vue?vue&type=template&id=3fa42ae5&scoped=true"
import script from "./StoreTeam.vue?vue&type=script&lang=js"
export * from "./StoreTeam.vue?vue&type=script&lang=js"
import style0 from "./StoreTeam.vue?vue&type=style&index=0&id=3fa42ae5&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fa42ae5",
  null
  
)

export default component.exports