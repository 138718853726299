<template>
<v-row justify="end" class="mr-2">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="80%"
    >
      <template v-slot:activator="{ on, attrs }">
           <v-btn color="orange" v-bind="attrs" v-on="on">
                <v-icon left>mdi-plus</v-icon>Add new member
            </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline">
          Add Member
        </v-card-title>
        <v-card-text>
            <v-form
                @submit.prevent
                ref="requestData"
                v-model="validRequestData"
              >
                <v-row>
                    <v-col lg="6" sm="6" cols="12">
                    <keep-alive>
                            <MemberAutocomplete
                                v-model="requestData.id"
                                @processing="handleProcessing"
                                @processed="handleProcessed"
                            ></MemberAutocomplete>
                        </keep-alive>
                    </v-col>
                    <v-col lg="6" sm="6" cols="12">
                        <v-select
                            :items="roles"
                            v-model="requestData.is_manager"
                            item-text="name"
                            item-value="id"
                            label="Team Role*"
                        ></v-select>
                    </v-col>
                </v-row>
            </v-form>


        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="orange darken-1"
            dark
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="green darken-1"
            dark
            @keyup.native.enter="store()"
            @click="store()"
          >
            Store
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import { mapGetters } from "vuex";
import validations from "@/views/partials/form/Validations.vue";
import { GET_TEAM_PERMISSIONS_REQUEST,GET_TEAM_REQUEST,CREATE_TEAM_REQUEST,UPDATE_TEAM_REQUEST } from "@/store/teams.module";
import { SHOW_SNACK } from "@/store/snack.module";
import MemberAutocomplete from "./MemberAutocomplete.vue";
export default {
  name: "AddMember",
  components: {
      MemberAutocomplete
  },
  data() {
    return {
      validations: validations,
      validRequestData: false,
      isProcessing: false,
      requestData: {},
      dialog: false
    };
  },
    computed: {
        ...mapGetters(["roles","users"]),
    },
  mounted() {
  },
  methods: {
    store() {
      this.$refs.requestData.validate();

      if (!this.validRequestData) {
        return;
      }
      let data = this.users.find(x=>x.id === this.requestData.id);
      data.is_manager = this.requestData.is_manager;
      this.$emit("member", data);
      this.requestData = {id:null,is_manager:null};
      this.$refs.requestData.resetValidation();
      this.dialog = false;
    },
    handleProcessing() {
        this.isProcessing = true;
    },
    handleProcessed() {
        this.isProcessing = false;
    },

  },
};
</script>